<script setup>
import SpeedyFooter from '@/components/SpeedyFooter.vue';
import SearchField from '@/components/SearchField.vue';
import HistoricalTimetables from '@/components/HistoricalTimetables.vue';
</script>
<template>
    <div>
        <search-field :search-text.sync="globals.searchText"></search-field>
        <router-view ref="router-view"></router-view>
        <speedy-footer></speedy-footer>
        <historical-timetables v-if="historicalTimetablesEnabled" />
    </div>
</template>
<script>
import globals from '@/globals.js';
import router from './router';

export default {
    data() {
        return { typed: '' }
    },
    computed: {
        historicalTimetablesEnabled() {
            return this.typed.indexOf('historicaltimetables') !== -1;
        }
    },
    mounted() {
        console.log(globals)
        document.addEventListener('keyup', event => {
            this.typed += event.key
            this.typed = this.typed.substr(this.typed.length - 256);

            if (this.typed.indexOf('enablefilter') !== -1) {
                localStorage['filter'] = 'true';
                location.reload();
            }

            if (this.typed.indexOf('substitutions') !== -1) {
                localStorage['substitutions'] = 'true';
                location.reload();
            }
            if (this.typed.indexOf('mastertimetable') !== -1) {
                router.push('/master-timetable');
                this.typed = '';
            }
            if (this.typed.indexOf('showstudentid') !== -1) {
                localStorage['showStudentID'] = 'true';
                location.reload();
                this.typed = '';
            }
        });
    }
}
</script>