import { reactive } from "vue";

const globals = reactive({
    searchText: '',
    loaded: false,
    error: null,
    showStudentList: false,
    LOGIN_REDIRECT: null
});

export default globals;
